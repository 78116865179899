/* eslint-disable max-lines */
import { togglePasswordAction } from 'actions/formAction'
import { Colors } from 'common-constants/colors'
import { FontSize } from 'common-constants/sizes'
import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { textOverflowStyles } from 'common/styles/textOverflow'
import { InputWrapper } from 'components/presentational/Form/InputWrapper'
import { ClosedEyeSvg } from 'components/presentational/svg/ClosedEyeSvg'
import { DropDownSvg } from 'components/presentational/svg/DropDownSvg'
import { OpenedEyeSvg } from 'components/presentational/svg/OpenedEyeSvg'
import { Search3Svg } from 'components/presentational/svg/Search3Svg'
import React, {
  ChangeEvent,
  Children,
  FC,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SelectHTMLAttributes,
  SyntheticEvent,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { createPasswordKey } from 'reducers/formReducer'
import styled, { css, StyledObject, RuleSet } from 'styled-components'
import { placeholderColor } from '../../constants'
import { Consumer, ValidatableElement } from './form'
import { linkColor } from './link'
import { VoidHandler, WithClassName } from 'common/types'
import { FormHandler } from 'components/presentational/Form/From.functions'
import { CustomChangeEvent } from 'components/presentational/Form/FormInputPhone/FormInputPhone.types'
import { zIndex } from './zIndex'

export const Dirty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

/** -1px хак, для бага на анройде, когда появляется тень с одного из боков */
export const boxShadowInfo = '0px 2px 0 -1px'
export const boxShadowHighlighted = '0px 3px 0 -1px'

export const InvalidMessageCss = css`
  color: #bb1515;
  font-size: 12px;
  margin-top: 7px;
  cursor: default;
`

export const InvalidMessage = styled.div`
  ${InvalidMessageCss};
`

export const InvalidCodeMessage: FC<
  {
    error?: string | null
    code: string
    id: string
  } & PropsWithChildren
> = ({ error, code, id, children }) => {
  if (error && error === code) {
    if (id) {
      return (
        <InvalidMessage>
          <FormattedMessage id={id} />
        </InvalidMessage>
      )
    }
    return <InvalidMessage>{children}</InvalidMessage>
  }
  return null
}

const inputSelectPadding = '15px 2px'

export const InputCss = css<{ valid?: boolean }>`
  padding: ${inputSelectPadding};
  box-shadow: ${(props) =>
    props.valid
      ? `${boxShadowInfo} rgba(0, 0, 0, 0.1)`
      : `${boxShadowHighlighted} #bb1515`};
  -webkit-appearance: none;
  border-radius: 0;
  margin-bottom: 2px;
  caret-color: ${Colors.defaultAction};

  &:not([required]) {
    box-shadow: none;
  }

  &:focus {
    box-shadow: ${boxShadowHighlighted} ${Colors.defaultAction};
  }

  ~ ${InvalidMessage} {
    display: none;
  }

  ${Dirty} &:invalid {
    box-shadow: ${boxShadowHighlighted} #bb1515;
    caret-color: #bb1515;

    ~ ${InvalidMessage} {
      display: block;
    }
  }
`

export const BaseInputElement = styled.div<{ valid?: boolean }>`
  ${InputCss};
`

BaseInputElement.defaultProps = {
  valid: true,
}

export const InputElement = styled(BaseInputElement).attrs({
  as: 'input',
})<{
  valid?: boolean
}>`
  border-width: 0;
  outline: none;
  font-size: 15px;
  ${InputCss};

  &[type='password']::-ms-reveal {
    display: none;
  }
`

InputElement.defaultProps = {
  valid: true,
}

const InputWithIcon = styled.div<{ $top?: number }>`
  position: relative;
  display: flex;
  flex-direction: column;

  /* TODO: При загрузке с сервера имя svg элемента не совпадает с именем правила,
  из-за этого стили, указанные ниже не применяются
  пока что установлен селектор для всех svg в поле
  нужно узнать почему имена отличаются */
  svg,
  svg {
    position: absolute;
    top: ${(props) => props.$top || 0}px;
    ${(props) => props.theme.right}: 0;
  }
`

export const WrapperField = styled.div<{ withDomain?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.withDomain ? 'padding-right: 88px; box-sizing: border-box;' : ''};
`

export const Nowrap = styled.span`
  white-space: nowrap;
  direction: ltr;
`

export type AppInputChangeHandler = (
  event: ChangeEvent | CustomChangeEvent
) => void

export type AppClickHandler = (event: SyntheticEvent<Element, Event>) => void

export interface AppInputProps {
  name: string
  type?: string
  value?: string | number
  placeholder?: string
  required?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  onChange?: AppInputChangeHandler
  message?: ReactNode
  withDomain?: boolean
  valid?: boolean
  onClick?: Function
  onInvalid?: FormHandler
  onFocus?: VoidHandler
  onBlur?: VoidHandler
  autoComplete?: string
  ['auto-complete']?: boolean
  /** Произвольная иконка инпута */
  icon?: { element: ReactNode; top?: number }
}

export const Input: FC<
  AppInputProps & {
    refNode?: MutableRefObject<HTMLInputElement | null>
  }
> = ({
  name,
  placeholder,
  message,
  refNode,
  withDomain,
  required,
  onInvalid,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { formReducer } = useShallowEqualSelector(({ formReducer }) => ({
    formReducer,
  }))

  const props = {
    name,
    message,
    refNode,
    withDomain,
    placeholder,
    required,
    onInvalid,
    /** После полной типизации детальней разобрать rest */
    ...rest,
  }

  if (props.type === 'password') {
    return (
      <Consumer>
        {(formId) => {
          const passwordKey = createPasswordKey(formId, name)
          const showPassword = formReducer.password[passwordKey]

          if (showPassword) {
            props.type = 'text'
          }

          const handleToggle: VoidHandler = () => {
            dispatch(togglePasswordAction(formId, name))
          }

          return (
            <InputWithIcon>
              <InputWrapper {...props} />
              {/* macOS Сафари прыгает если показывать "глазик" по условию */}
              {/* https://redmine.mamba.ru/issues/112886 */}
              {showPassword ? (
                <OpenedEyeSvg size={24} onClick={handleToggle} />
              ) : (
                <ClosedEyeSvg size={24} onClick={handleToggle} />
              )}
            </InputWithIcon>
          )
        }}
      </Consumer>
    )
  }
  if (props.type === 'search') {
    return (
      <InputWithIcon>
        <InputWrapper {...props} />
        {!props.value && <Search3Svg size={24} />}
      </InputWithIcon>
    )
  }

  const { icon } = props

  if (icon) {
    delete props.icon

    return (
      <InputWithIcon $top={icon.top}>
        <InputWrapper {...props} />
        {icon.element}
      </InputWithIcon>
    )
  }
  return <InputWrapper {...props} />
}

export const InputRef = forwardRef<HTMLInputElement, AppInputProps>(
  (props, ref) => (
    <Input refNode={ref as MutableRefObject<HTMLInputElement>} {...props} />
  )
)

export const SelectLabel = styled.label<{
  customLabelCss?: RuleSet
}>`
  display: flex;
  position: static;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  ${(props) => props.customLabelCss};
`

const SelectElement = styled(InputElement).attrs({
  as: 'select',
})<{
  valid?: boolean
}>`
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  -webkit-appearance: none;
  position: relative;
  color: ${(props) => (props.color ? props.color : `${placeholderColor}`)};
  -moz-appearance: none;
  cursor: pointer;
  text-transform: capitalize;
  font-size: ${FontSize.titleName}px;
  color: ${Colors.dark};

  ${InputCss};

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

SelectElement.defaultProps = {
  valid: true,
}

export const Icon = styled(DropDownSvg)`
  position: absolute;
  ${(props) => props.theme.right}: 0;
  top: calc(50% - 3px);
`

const SelectValue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

const SelectPlaceholder = styled(SelectValue)`
  display: block;
  padding: ${inputSelectPadding};
  margin-bottom: 2px;
  color: ${placeholderColor};
  ${textOverflowStyles};
  max-width: calc(100% - 15px);
  color: ${Colors.dark};
  font-size: ${FontSize.titleName}px;
  background-color: ${Colors.white};
  pointer-events: none;
  z-index: ${zIndex.base};
`

export interface SelectProps
  extends WithClassName,
    HTMLAttributes<HTMLSelectElement> {
  name: string
  value?: number | string
  placeholder?: string
  onInvalid?: FormHandler
  refNode?: ForwardedRef<HTMLSelectElement>
  disabled?: boolean
  required?: boolean
}

export const Select: FC<SelectProps> = ({
  name,
  value,
  placeholder,
  children,
  className,
  onInvalid,
  refNode,
  ...rest
}) => {
  return (
    <SelectLabel className={className}>
      {placeholder && !value && (
        <SelectPlaceholder>{placeholder}</SelectPlaceholder>
      )}
      <DropDownIcon size={16} />
      <ValidatableElement name={name} onInvalid={onInvalid}>
        <SelectElement ref={refNode} value={value} {...rest}>
          {children}
        </SelectElement>
      </ValidatableElement>
    </SelectLabel>
  )
}

export const SelectRef = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => <Select refNode={ref} {...props} />
)

export const selectLabelValueCss = css<{ $color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.theme.paddingRight}: 5px;
  color: ${(props) => props.$color};
  white-space: nowrap;

  &:focus {
    outline: 0;
  }
`

export const SelectLabelValue = styled.div<{ $color?: string }>`
  ${selectLabelValueCss}
  ${(props) => props.theme.marginRight}: 15px;
`

SelectLabelValue.defaultProps = {
  $color: 'rgba(0, 0, 0, 0.4)',
}

export const StaticSelectElement = styled.select`
  border-width: 0;
  padding: 0;
  -webkit-appearance: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  opacity: 0.001;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

export const SelectLabelValueColor = (value, defaultColor) => {
  if (Boolean(value)) {
    if (defaultColor) {
      return linkColor
    }

    return 'rgba(0,0,0,0.4)'
  }

  return placeholderColor
}

export const StaticSelect: FC<
  {
    name: string
    renderLabel?: any
    defaultColor?: boolean
    customLabelCss?: RuleSet
    renderPlaceholder?: (selectedChild: ReactNode) => ReactNode
  } & SelectHTMLAttributes<Element>
> = ({
  name,
  value,
  children,
  renderLabel = (selectedChild: ReactNode) =>
    (selectedChild as ReactElement).props.children,
  renderPlaceholder,
  className,
  customLabelCss,
  defaultColor = false,
  ...rest
}) => {
  const selectedChild = (Children.toArray(children) as ReactElement[]).find(
    ({ props }) => props.value === value
  )

  return (
    <SelectLabel customLabelCss={customLabelCss} className={className}>
      <SelectLabelValue $color={SelectLabelValueColor(value, defaultColor)}>
        {renderLabel(selectedChild)}
        {renderPlaceholder && renderPlaceholder(selectedChild)}
      </SelectLabelValue>
      <DropDownIcon size={16} />
      <ValidatableElement name={name}>
        <StaticSelectElement {...rest} value={value}>
          {children}
        </StaticSelectElement>
      </ValidatableElement>
    </SelectLabel>
  )
}

export const DropDownIcon = styled(DropDownSvg)`
  position: absolute;
  ${(props) => props.theme.right}: 0;
  top: calc(50% - 8px);
`
