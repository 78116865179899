import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { WithChildren, WithClassName } from 'common/types'
import React, { CSSProperties, ElementType, forwardRef } from 'react'
import {
  formaDJRCyrillicMicroFontFamily,
  formaDJRCyrillicTextFontFamily,
} from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

type Props = {
  fontSize: FontSize
  fontWeight?: FontWeight
  as?: ElementType
  style?: CSSProperties // Использовать в редких случаях
} & WithChildren &
  WithClassName

export const Typography = forwardRef<HTMLHeadingElement, Props>(
  ({ className, fontSize, fontWeight = 400, children, as, style }, ref) => {
    const Component = typographyMap[fontSize] || typographyMap['16']

    return (
      <Component
        ref={ref}
        style={style}
        className={cx(commonCss, fontWeightMap[fontWeight], className)}
        as={as}
      >
        {children}
      </Component>
    )
  }
)

export type FontSize = 40 | 32 | 24 | 20 | 16 | 14 | 11
type FontWeight = 400 | 500 | 700

const commonCss = css`
  font-style: normal;
  font-weight: 400;
  color: var(--foreground-default, #2e2a29);
`
const TypographyLargeHeader = styled.h1`
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.64px;
  font-family: ${formaDJRCyrillicTextFontFamily};
`
const TypographyMainHeader = styled.h2`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
  font-family: ${formaDJRCyrillicTextFontFamily};
`
const TypographySubheader = styled.h3`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-family: ${formaDJRCyrillicTextFontFamily};
`
const TypographyMain = styled.p`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
  font-family: ${formaDJRCyrillicMicroFontFamily};
`
const TypographyBody = styled.p`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  font-family: ${formaDJRCyrillicMicroFontFamily};
`
const TypographyCaption = styled.p`
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.44px;
  font-family: ${formaDJRCyrillicMicroFontFamily};
`

const typographyMap: Record<
  FontSize,
  typeof TypographyLargeHeader | typeof TypographyCaption
> = {
  40: TypographyLargeHeader,
  32: TypographyLargeHeader,
  24: TypographyMainHeader,
  20: TypographySubheader,
  16: TypographyMain,
  14: TypographyBody,
  11: TypographyCaption,
}

const fontWeightMap: Record<FontWeight, ReturnType<typeof css>> = {
  400: css`
    font-weight: 400;
  `,
  500: css`
    font-weight: 500;
  `,
  700: css`
    font-weight: 700;
  `,
}
