/**
 * Документация бекенда:
 * https://developers.wamba.com/api/services/uni_notice_index/index.html
 * https://developers.wamba.com/comet/mamba_uni_notice__recipientId_-publish/index.html
 */

import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { definitions } from 'api/generated/oauth-vendors'
import { definitions as cascade_change } from 'api/generated/cascade_change'

/**
 * TYPE_SHORTCUT - маленький notice справа или сверху, у него не может быть поля DISPLAY_
 * TYPE_NOTIFICATION - модальное окно, или блок который встраивается в тело страницы в зависимости от DISPLAY_
 */

export const DISPLAY_CONTAINER = 'container'
export const DISPLAY_INLINE = 'inline'

export type UniNoticeDisplayTypes =
  | typeof DISPLAY_CONTAINER
  | typeof DISPLAY_INLINE

export const TYPE_SHORTCUT = 'shortcut'
export const TYPE_NOTIFICATION = 'notification'

export type UniNoticeViewTypes = typeof TYPE_SHORTCUT | typeof TYPE_NOTIFICATION

export enum UniNoticeType {
  activity = 'activity',
  events = 'events',
  popularity = 'popularity',
  search = 'search',
  search_filters = 'search_filters',
  encounters = 'encounters',
  encounters_filters = 'encounters_filters',
  contact_list = 'contact_list',
  messaging = 'messaging',
  profile = 'profile',
  my_profile = 'my_profile',
  profile_settings = 'profile_settings',
  settings = 'settings',
  up_showcase = 'up_showcase',
  likes_showcase = 'likes_showcase',
  gifts_showcase = 'gifts_showcase',
  photoline_showcase = 'photoline_showcase',
  edit_about_me = 'edit-about-me',
  vip_showcase = 'vip_showcase',
  coins_showcase = 'coins_showcase',
  set_notice = 'set_notice',
  set_vip = 'set_vip',
  set_privacy = 'set_privacy',
  turn_off_incognito = 'turn-off-incognito',
  verification = 'verification',
  add_photo = 'add_photo',
  upload_photo = 'upload_photo',
  undelete_photo = 'undelete-photo',
  create_support_ticket = 'create_support_ticket',
  create_support_email = 'create_support_email',
  text_agree = 'text_agree',
  text_confidentiality = 'text_confidentiality',
  open_viber_for_profile_verification = 'open-viber-for-profile-verification',
  open_telegram_for_profile_verification = 'open-telegram-for-profile-verification',
  open_whatsapp_for_profile_verification = 'open-whatsapp-for-profile-verification',
  request_incognito_access = 'request-incognito-access',
  messenger = 'messenger',
  none = 'none',
  photo_verification = 'photo_verification',
  add_to_favorite = 'add-to-favorite',
  open_support_ticket = 'open-support-ticket',
  confirm_photo_deletion = 'confirm-photo-deletion',
  request_mamba_password = 'request-mamba-password',
  upload_rules = 'upload-rules',
  login_to_teamo = 'login-to-teamo',
  ignore = 'ignore',
  external_messenger_open_for_auth = 'open_external_messenger_for_auth',
  external_link = 'external-link',
  user_banned = 'user-banned',
  blocked_by_track_reasons = 'blocked-by-track-reasons',
  user_ban_reasons = 'user-ban-reasons',
  show_another_uninotice = 'show-another-uninotice',
  show_this_is_me_widget = 'show-this-is-me-widget',
  confirm = 'confirm',
  close_storefront = 'close-storefront',
  password_recovery = 'password_recovery',
  account_removal = 'account-removal',
  inviter = 'inviter',
  billing_subscription_mеgаfon = 'billing-subscription-mеgаfon',
  billing_subscription_mеgаfon_activated = 'billing-subscription-mеgаfon-activated',
  billing_subscription_beeline = 'billing-subscription-beeline',
  billing_subscription_beeline_activated = 'billing-subscription-beeline-activated',
  user_geolocation_disabled = 'user-geolocation-disabled',
  open_oauth = 'open-oauth',
  show_vip_showcase_slide_hide_ad = 'show-vip-showcase-slide-hide-ad',
  photo_delete_confirmation = 'photo-delete-confirmation',
  photo_last_portrait_deletion = 'photo-last-portrait-deletion',
  enable_setting_messages_only_from_verified = 'enable-setting-messages-only-from-verified',
}

export type UniNoticeIdTypes = string | UniNoticeType

/**
 * TODO Расписать приходящие данные по типу сообщений
 */
export interface UniNoticeData {
  recipientId?: number
  userId?: number
  photoId?: number
  photosIds?: number[]
  /**
   * Нотис на который нужно перейти.
   *
   * Упоминание в https://youtrack.mamba.ru/issue/M-7684#focus=Comments-4-54439.0-0
   */
  noticeId?: string
  link?: string
  oauthVendor?: definitions['OAuthVendor']
  fields?: cascade_change['ProfileFieldCascadeChange']['name'][]
}

/**
 * Причина в replace при открытии витрины у экшена
 * https://redmine.mamba.ru/issues/110947
 * @deprecated более не нужен, из-за логики завязанной на открытие
 * модала
 */
export const shouldPushInlineNoticeId: UniNoticeId[] = []

export const serverUniNoticeParameter = 'notice'

export const uniNoticeIdTypesLink = [UniNoticeType.login_to_teamo]
