import { styled } from '@linaria/react'
import { Typography } from 'components/designSystem/Typography/Typography'
import rotatePhoneSvg from 'components/designSystem/svg/rotate-phone.svg'
import { zIndex } from 'components/designSystem/zIndex'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const RotatePhoneOverlay: FC = () => {
  const redesign = useRedesign()
  const { touch } = useShallowEqualSelector(({ systemReducer: { touch } }) => ({
    touch,
  }))

  if (!redesign || !touch) {
    return null
  }

  return (
    <RotatePhoneOverlayWrapper
      style={{
        display: 'none', // На случай, если не загрузился .css файл.
      }}
    >
      <RotatePhoneOverlayInner>
        <Image src={rotatePhoneSvg} alt="rotate phone" loading="lazy" />
        <Block>
          <FormattedMessage
            id="app.rotate_phone_overlay_text"
            defaultMessage="<p>По-моему, так не очень…</p><p>Будет красивее, если перевернуть экран.</p>"
            values={{ p: (chunks) => <Text fontSize={16}>{chunks}</Text> }}
          />
        </Block>
      </RotatePhoneOverlayInner>
    </RotatePhoneOverlayWrapper>
  )
}

const RotatePhoneOverlayWrapper = styled.div`
  display: none;
  @media screen and (min-aspect-ratio: 13/9) {
    display: fixed !important; // Нужно, чтобы перебить inline стиль. Inline стиль нужен.
  }
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.topMost};
  background: var(--background-page, #fff);
`
const RotatePhoneOverlayInner = styled.div`
  text-align: center;
`
const Block = styled.div`
  margin-top: var(--spacing-32px, 32px);
`
const Image = styled.img``
const Text = styled(Typography)``
