/* eslint-disable max-lines */
import {
  AuthorizationTypes,
  CONFIRM_PHONE_CODE,
  FETCH_IS_AUTHORIZATION_MAIL_RU,
  REGISTRATION_DATA,
  RESET_PASSWORD_ATTEMPT,
  SMS_TIMER_FINISHED,
  UPDATE_AUTH,
  UPDATE_AUTH_FIELD_ACTION,
} from 'actions/authorizationAction'
import { LAST_AUTH_METHOD } from 'actions/authorization/updateLastAuthMethodAction'
import { REGISTER_ACTION } from 'actions/authorization/registrationAction'

import {
  apiResultHasErrors,
  extractFieldsValues,
  formBuilderHasField,
  isUnexpectedServerError,
} from 'api/index'

import { getApi6ErrorCode } from 'api/functions'
import { locationField } from 'common/constants'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { defaultUpdateFieldReducer } from 'reducers/defaultUpdateFieldReducer'
import { extractFormErrors } from 'api/FormBuilder'
import {
  RESET_PASSWORD,
  ResetPasswordTypes,
} from 'actions/authorization/resetPasswordAction'
import { LOGIN_ACTION } from 'actions/authorization/loginAction'
import { OauthLoginAction } from 'actions/authorization/oauthLoginAction'
import { LOCATION_CHANGE, RouterActions } from 'redux-first-history'
import { Reducer } from 'redux'
import { defaultState } from 'reducers/authorization/defaultState'
import { AuthorizationState } from 'reducers/authorization/AuthorizationState'
import {
  REGISTER_WITH_EMAIL,
  StepRegisterWithEmailAction,
} from 'actions/form/stepRegisterWithEmailAction'
import { UPDATE_MINI, UpdateMiniAction } from 'actions/system/updateMiniAction'
import {
  FETCH_MINI,
  FetchMiniIfNeededAction,
} from 'actions/user/fetchMiniIfNeededAction'
import {
  FETCH_VENDORS_LIST,
  RESET_VENDORS_LIST,
  VendorsAction,
} from 'actions/authorization/vendorsListAction'
import {
  CLEAR_AUTHORIZATION_ERROR,
  ClearAuthorizationTypes,
} from 'actions/authorization/clearAuthorizationAction'
import {
  authorizeBySecretPlainAction,
  AUTHORIZE_BY_SECRET,
} from 'actions/authorization/authorizeBySecretAction'
import {
  AUTHORIZE_MAIL_RU_TOKEN,
  AuthorizeByTokenAtMailruAction,
} from 'actions/system/authorizeByTokenAtMailruAction'
import { LOGOUT_ACTION } from 'actions/authorization/logoutAction'
import { MiniApiResult } from 'api/user/MiniApiResult'
import { isActionWithoutErrors } from 'reducers/isActionWithoutErrors'
import { LAST_CLICKED_AUTH_VENDOR } from 'actions/authorization/updateLastClickedAuthVendor'

export const authorizationReducer: Reducer<
  AuthorizationState,
  | AuthorizationTypes
  | StepRegisterWithEmailAction
  | ResetPasswordTypes
  | OauthLoginAction
  | RouterActions
  | UpdateMiniAction
  | FetchMiniIfNeededAction
  | VendorsAction
  | ClearAuthorizationTypes
  | ReturnType<typeof authorizeBySecretPlainAction>
  | AuthorizeByTokenAtMailruAction
> = (state = defaultState, action): AuthorizationState => {
  switch (action.type) {
    case LOGIN_ACTION:
    case REGISTER_ACTION:
    case REGISTER_WITH_EMAIL:
      if (action.ready) {
        const newState = {
          ...state,
          authorizing: false,
          // используется для корректной дореги
          registrationVendor: (action as OauthLoginAction).vendor,
        }

        /**
         * Непростой кейс для типизации.
         * Разобраться с TODO ниже и затипизировать.
         */
        const result = action.result as any

        if (action.error || apiResultHasErrors(result)) {
          if (action.error) {
            newState.genericError = true
          }
          if (action.result) {
            /**
             * TODO: надо c этим разобраться
             * Тут перемешалось два ответа из разных апи
             */
            newState.authorized =
              result.internalError &&
              Boolean(result.isAuth || result.internalError?.isAuth)
          }
          if (result?.formBuilder) {
            newState.formError = extractFormErrors(result.formBuilder)
          }
          return newState
        }

        if (result?.profile) {
          const { name } = result.profile
          newState.profile = {
            ...state.profile,
            name,
          }
        }

        if (action.type === REGISTER_ACTION) {
          newState.registrated = true
        }

        newState.authorized = Boolean(result?.isAuth)

        if (result?.formBuilder) {
          newState.formError = extractFormErrors(result.formBuilder)
        }

        return newState
      }
      return {
        ...state,
        genericError: false,
        authorizing: true,
      }

    case AUTHORIZE_BY_SECRET:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          authorizing: true,
          authorizedBySecret: false,
          authorizationBySecretFailed: false,
        }),
        (result) => {
          if (result) {
            return {
              authorizing: false,
              authorized: true,
              authorizedBySecret: true,
              authorizationBySecretFailed: false,
              profile: {
                id: result.profileMini.id,
                login: result.login,
                name: result.profileMini.name,
              },
            }
          }

          return state
        },
        () => ({
          authorizing: false,
          authorizedBySecret: false,
          authorizationBySecretFailed: (action as ReturnType<
            typeof authorizeBySecretPlainAction
          >).manual
            ? false
            : true,
        })
      )

    case AUTHORIZE_MAIL_RU_TOKEN:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          authorizing: true,
          authorizedBySecret: false,
          authorizationBySecretFailed: false,
        }),
        (result) => ({
          authorizing: false,
          authorized: true,
          authorizedBySecret: true,
          authorizationBySecretFailed: false,
        }),
        () => ({
          authorizing: false,
          authorizedBySecret: false,
          authorizationBySecretFailed: true,
        })
      )

    case LOCATION_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          password: '',
        },
      }

    case FETCH_IS_AUTHORIZATION_MAIL_RU:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          needRegistrationLoveMailRu: false,
          loveMailruAuth: false,
        }),
        () => ({
          needRegistrationLoveMailRu:
            action.result?.result === 'goto_mailru_login', // пользователь не зарегистрирован на mailru
          loveMailruAuth: action.result?.result === 'goto_mamba_registration', // пользователь не зарегистрирован на mamba.ru но зарегистрирован на mail.ru
          loveMailRuMambaUser: action.result?.result === 'mamba_user', // пользователь зарегистрирован на love.mail.ru напрямую (без кроссрегистрации mail.ru) */
        })
      )

    case LOGOUT_ACTION:
      if (action.ready) {
        const newState = {
          ...state,
          authorizing: false,
        }
        if (action.error || apiResultHasErrors(action.result)) {
          return newState
        }
        setNotAuthorizedState(newState)
        newState.authorized = Boolean(action.result?.isAuth)
        return newState
      }
      return {
        ...state,
        authorizing: true,
      }

    case UPDATE_AUTH:
      return {
        ...state,
        authorized: action.authorized,
      }

    case UPDATE_AUTH_FIELD_ACTION:
      // TODO разобраться с типами формы
      // @ts-ignore
      return defaultUpdateFieldReducer(state, action)

    case FETCH_VENDORS_LIST:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          vendorsLoading: true,
        }),
        (result) => ({
          vendors: result,
          vendorsLoading: false,
          vendorsLoaded: true,
        }),
        () => ({
          vendorsLoading: false,
        })
      )

    case RESET_VENDORS_LIST:
      return {
        ...state,
        vendors: [],
        vendorsLoading: false,
        vendorsLoaded: false,
      }

    case RESET_PASSWORD:
      if (action.ready) {
        if (isUnexpectedServerError(action.result)) {
          return {
            ...state,
            resettingPassword: false,
            unexpectedServerError: true,
          }
        }

        const errorCode = getApi6ErrorCode(action.result)
        if (errorCode) {
          return {
            ...state,
            resettingPassword: false,
            resetErrorCode: errorCode,
          }
        }
        return {
          ...state,
          resettingPassword: false,
          resetErrorCode: null,
          unexpectedServerError: false,
          smsCodeHasBeenSent: action.isPhone,
        }
      }
      return {
        ...state,
        resettingPassword: true,
        smsTimerFinished: false,
        unexpectedServerError: false,
        smsCodeHasBeenSent: false,
      }

    case CONFIRM_PHONE_CODE:
      if (action.ready) {
        const errorCode = getApi6ErrorCode(action.result)
        if (errorCode) {
          return {
            ...state,
            resettingPassword: false,
            smsErrorCode: errorCode,
            smsCodeHasBeenSent: true,
          }
        }
        return { ...state, resettingPassword: false, smsCodeHasBeenSent: true }
      }
      return {
        ...state,
        resettingPassword: true,
        unexpectedServerError: false,
        smsCodeHasBeenSent: false,
      }

    case RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        resetErrorCode: null,
        form: {
          ...state.form,
          smsCode: '',
        },
        smsErrorCode: null,
        smsCodeHasBeenSent: false,
        unexpectedServerError: false,
      }

    case SMS_TIMER_FINISHED:
      return {
        ...state,
        smsTimerFinished: action.finished,
      }

    case REGISTRATION_DATA:
      if (action.ready && isActionWithoutErrors(action) && action.result) {
        const { formBuilder } = action.result
        const { email, name, gender, location, birth } = extractFieldsValues(
          formBuilder,
          {
            location: { value: '' },
          }
        )
        const newState = {
          ...state,
          hasLocation: formBuilderHasField(formBuilder, locationField),
          form: {
            ...state.form,
            // Вначале смотрим на значния из формбилдера (например данные из соц. сетей)
            // потом берем предыдущее значение введенное пользователем
            email: email.value || state.form.email,
            emailEnable: email.enable || state.form.emailEnable,
            name: name.value || state.form.name,
            gender: gender.value || state.form.gender,
            location: location.value,
            locationDisplayValue: location.displayName,
          },
        }
        if (birth.value) {
          const date = new Date(birth.value)
          newState.form.day = String(date.getDate())
          newState.form.month = String(date.getMonth() + 1)
          newState.form.year = String(date.getFullYear())
        }

        if (newState.needRegistrationLoveMailRu) {
          newState.needRegistrationLoveMailRu = false
        }

        newState.miniLoading = false
        newState.registrationDataLoaded = true
        return newState
      }

      return { ...state, miniLoading: true, loveMailruLoading: false }

    case FETCH_MINI:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ miniLoading: true }),
        () => ({
          profile: fromMini(action.result!),
          authorized: action.result?.isAuth,
          miniLoading: false,
        }),
        () => {
          if (
            action.result?.internalError &&
            action.result.internalError.anketa
          ) {
            return {
              profile: fromMini(action.result?.internalError!),
              authorized: action.result.internalError?.isAuth,
              miniLoading: false,
            }
          }
          return { miniLoading: false }
        }
      )

    case UPDATE_MINI:
      return defaultPromiseReducer(state, action, undefined, () => ({
        profile: fromMini(action.result!),
      }))

    case LAST_AUTH_METHOD:
      return {
        ...state,
        lastAuthMethod: action.method,
        lastClickedAuthVendor: null,
      }

    case LAST_CLICKED_AUTH_VENDOR:
      return {
        ...state,
        lastClickedAuthVendor: action.vendor,
      }

    case CLEAR_AUTHORIZATION_ERROR:
      return {
        ...state,
        formError: {},
      }

    default:
      return state
  }
}

const setNotAuthorizedState = (state: AuthorizationState) => {
  state.authorized = false
  state.registrationDataLoaded = false
  state.miniLoading = false
  state.profile = {} as AuthorizationState['profile']
}

const fromMini = ({ anketa }: MiniApiResult): AuthorizationState['profile'] => {
  if (anketa) {
    const { id, login, name, isReal } = anketa
    return { id, login, name, isReal }
  }

  return {} as AuthorizationState['profile']
}
