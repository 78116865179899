import { LOCALES_REGEXP } from 'common-constants/locale'
import { IncomingMessage, ServerResponse } from 'node:http'
import { removeLocaleFromPath2 } from 'functions/language'

export const localeRegExp = new RegExp(`^/(${LOCALES_REGEXP})\\W.*?`, 'i')
export const localeOnlyRegExp = new RegExp(`^/(${LOCALES_REGEXP})/?$`, 'i')

/**
 * Часть урлов работает и так.
 * Надо будет проверить регрессом, а потом выпилить.
 */
const phpUrls = [
  '/share',
  '/oauth',
  /**
   * login.phtml это часть oauth.
   */
  '/login.phtml',
  '/upload/odnoklassniki/proxy.phtml',
]

/**
 * Список принимаемых пыхом уров:
 * https://youtrack.mamba.ru/issue/M-2664#focus=Comments-4-21418.0-0
 * @param request
 * @param partnerId
 * @param ip
 */
export const shouldProxyToPhp = (
  request: IncomingMessage,
  partnerId: number,
  ip: string
) => {
  const urlWithoutLocale = removeLocaleFromPath2(request.url!)

  return phpUrls.some((url) => urlWithoutLocale.startsWith(url))
}
