export const handleIntlError = (error) => {
  if (error.code === 'MISSING_TRANSLATION') {
    if (process.env.production) {
      return console.warn('Missing translation', error.message)
    } else {
      return
    }
  }
  throw error
}
