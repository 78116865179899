import { useDispatch } from 'react-redux'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useEffect } from 'react'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'
import { push, replace } from 'functions/router'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  chatPath,
  contactPath,
  inlineNoticePath,
  profilePath,
  settingsModalPath,
} from 'components/paths'
import { noticePath as notice } from 'common/constants'
import { SUPPORT_BOT_PROFILE_ID } from 'components/page/Chat/Messenger/Messenger.constants'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { privacyPath } from 'components/routes/mobileAppSupportRoutes'
import { gdprDeleteWarningPath } from 'components/page/Settings/Settings.paths'
import { stopChatSupportTicketSuggestedNoticeId } from 'components/uninotice/uniNoticeIdList'
import { noticePath } from 'components/uninotice/UniNotice.paths'

//
/**
 * https://youtrack.mamba.ru/issue/M-4531#focus=Comments-4-30169.0-0
 * Если попробовать вернуть будет warning как выше в комменте
 */
const emptyArray = []

/**
 *
 */
export const useProfileNoticeBanned = () => {
  const dispatch = useDispatch()

  const { collection, profileBanned, profileRemoved } = useShallowEqualSelector(
    ({
      uniNoticeReducer,
      errorReducer: { profileBanned, profileRemoved },
    }) => ({
      /**
       * Если редьюсер нотисов не прогрузился еще, значит
       * и нотиса user_banned тоже нет.
       */
      collection: uniNoticeReducer?.collection ?? emptyArray,
      profileBanned,
      profileRemoved,
    })
  )

  /**
   * profileRemoved - https://redmine.mamba.ru/issues/121813
   * в случае если у пользователя оказалось оба флага,
   * то основной это removed. Не надо перекидывать на banned.
   */
  useEffect(() => {
    if (
      profileBanned &&
      !profileRemoved &&
      Object.keys(collection).includes(UniNoticeType.user_banned) &&
      !isAccessibleRouteForBannedUser(window.location.pathname)
    ) {
      /** Логичнее чтобы был реплейс, а не пуш, если юзер заблокирован */
      dispatch(
        replace(
          mergeAllUrls(inlineNoticePath, UniNoticeType.user_banned),
          false,
          'user banned redirect'
        )
      )
    }
  }, [dispatch, profileBanned, profileRemoved, collection])
}

const isAccessibleRouteForBannedUser = (path: string) => {
  switch (path) {
    case mergeAllUrls(notice, UniNoticeType.user_banned):
    case mergeAllUrls(notice, UniNoticeType.user_ban_reasons):
    /**
     * По-идее надо отказаться тут от модала, но в отдельной задаче.
     * Так как есть другие использования.
     *
     * /notice/user-banned/uni-notice/user-ban-reasons
     */
    case mergeAllUrls(
      notice,
      UniNoticeType.user_banned,
      noticePath,
      UniNoticeType.user_ban_reasons
    ):
    case mergeAllUrls(chatPath, SUPPORT_BOT_PROFILE_ID, contactPath):
    /**
     * /chats/4/contact/uni-notice/support-tickets-suggested
     */
    case mergeAllUrls(
      chatPath,
      SUPPORT_BOT_PROFILE_ID,
      contactPath,
      noticePath,
      stopChatSupportTicketSuggestedNoticeId
    ):
    /** /profile/settings/privacy/gdpr-delete-warning https://youtrack.mamba.ru/issue/M-2100 */
    case mergeAllUrls(
      profilePath,
      settingsModalPath,
      privacyPath,
      gdprDeleteWarningPath
    ):
    case shellPath: // https://redmine.mamba.ru/issues/122308
      return true
    default:
      return false
  }
}
