/* eslint-disable max-lines */
import React from 'react'

import SettingsLoadable from './page/Settings/SettingsLoadable'

import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  announceAddPath,
  appUniWebPath,
  blockedByIpReasonsLegacyPath,
  blockedByIpReasonsPath,
  blockedByPersonalPath,
  blockedByTrackReasonsLegacyPath,
  blockedByTrackReasonsPath,
  blockedProfilePath,
  cashOutPath,
  datingProfilePath,
  diamondsPath,
  featuredPhotosPath,
  indexPath,
  internalServerErrorPath,
  logoutPath,
  notFoundPath,
  photoCommentOldPath,
  photoRestrictionsPath,
  photoviewerOldPath,
  profileOkPhotoUploadPath,
  profileOkPhotoUploadVendorErrorPath,
  searchFriendPath,
  settingsPath,
  userProfilePath,
  vipPromoPath,
  contestPromoPath,
  downloadAppPath,
  guaranteedImpressionsResultPath,
  hitListPath,
  registrationPath,
} from './paths'
import { MainSearchSettingsLoadable } from './page/MainSearch/MainSearchSettingsLoadable'
import StoreFrontLoadable from './storefront/StoreFrontLoadable'
import RestorePasswordLoadable, {
  restorePasswordPath,
} from './page/RestorePassword/RestorePasswordLoadable'
import { CashOutLoadable } from './page/CashOut/CashOutLoadable'
import { FeaturedPhotosStartLoadable } from './storefront/StoreFrontFeaturedPhotos/FeaturedPhotosStartLoadable'
import { GetLinkForDownloadAppLoadable } from 'components/page/GetLinkForDownLoadApp/GetLinkForDownloadAppLoadable'

import { DiamondsModalLoadable } from 'components/page/DiamondsInfo/DiamondsModalLoadable'
import { searchSettingsPath } from 'components/page/Search/Search.paths'
import DatingProfileLoadable from './page/DatingProfile/DatingProfileLoadable'
import { deleteProfileRoutes } from 'components/page/DeleteProfile/DeleteProfile.routes'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { IndexSwitcher } from 'components/layout/IndexSwitcher'
import { BlockedByIpListLoadable } from 'components/page/Boarding/BlockedModalList/BlockedByIpListLoadable'
import { CaptchaPageLoadable } from 'components/page/Captcha/CaptchaPageLoadable'
import { BlockedByTrackLoadable } from 'components/page/Boarding/BlockedModalList/BlockedByTrackLoadable'
import { VerifyRealUsingSocialNetworkLoadable } from 'components/page/Settings/Verification/VerifyRealUsingSocialNetworkLoadable'
import { NotFoundPageLoadable } from 'components/page/NotFoundPage/NotFoundPageLoadable'
import { GenericLayoutContextWrapper } from 'components/layout/WrapperLayout'
import { GuaranteedImpressionsResultLoadable } from 'components/page/GuaranteedImpressionsResult/GuaranteedImpressionsResultLoadable'
import { InviteFriendLoadable } from 'components/page/Promo/InviteFriendPromo/InviteFriendPromoLoadable'
import {
  otpStoreFrontPath,
  shortStoreFrontPath,
  storeFrontPath,
} from 'components/storefront/StoreFront.paths'
import { AppRoute } from 'components/components.types'
import { loginPath } from 'components/page/Boarding/boarding.paths'
import {
  settingsVerificationPath,
  settingsPrivacyPath,
} from 'components/page/Settings/Settings.paths'
import { photoViewerBasePath } from 'components/page/PhotoViewer/PhotoViewer.paths'
import { legacySupportRoutes } from 'components/routes/legacySupportRoutes'
import { mobileAppSupportRoutes } from 'components/routes/mobileAppSupportRoutes'
import {
  stepRegistrationPath,
  stepRegistrationStepPath,
} from 'components/page/StepRegistration/StepRegistration.paths'
import { LegacyMessengerRedirect } from 'components/system/LegacyMessengerRedirect'
import { uniNoticeFullPath } from 'components/uninotice/UniNotice.paths'
import { systemRoutes } from 'components/routes/systemRoutes'
import { emailRoutes } from 'components/routes/emailRoutes'
import {
  ProfileRedirect,
  profileRedirectPath,
} from 'components/system/ProfileRedirect'
import {
  ProfileAlbumRedirect,
  profileAlbumRedirectPath,
} from 'components/system/ProfileAlbumRedirect'
import { profileFacebookPhotoUploadPath } from 'common-constants/facebook'
import { ProviderClassMateRedirect } from './page/UploaderPhotos/ProviderClassMateRedirect'
import { ProviderFacebookRedirect } from './page/UploaderPhotos/ProviderFacebookRedirect'
import { supportFormPath } from 'components/page/Boarding/SupportForm/SupportForm.paths'
import SupportFormLoadable from 'components/page/Boarding/SupportForm/SupportFormLoadable'
import { GenericUniNotice } from 'components/uninotice/layout/GenericUniNotice'
import { PhotoViewerLoadable } from 'components/page/PhotoViewer/PhotoViewerLoadable'
import { backgroundOnlyRoutes } from 'components/routes/backgroundOnlyRoutes'
import { PhotoCommentOldPathRedirect } from 'components/system/PhotoCommentOldPathRedirect'
import { PhotoviewerOldPathRedirect } from 'components/system/PhotoviewerOldPathRedirect'
import { externalLinkRedirectPath } from 'components/page/ExternalLink/ExternalLink.paths'
import { noticeEmailsRoute } from './system/redirect/NoticeEmail/NoticeEmailsConfig'
import { pwaRoutes } from 'components/routes/pwaRoutes'
import { profileInviteFriendsAfterCallbackRoute } from 'components/page/ProfileInviteFriends/ProfileInviteFriends.routes'
import { IndexStepEntry } from 'components/page/StepRegistration/IndexStepEntry'
import { Replace } from 'components/system/Replace'
import { StandaloneStoreFront } from 'components/storefront/StandaloneStoreFront'
import { legacyDeletedServiceRoutes } from 'components/system/legacyServiceDelete/legacyDeletedServiceRoutes'
import { oldLoginRoutes } from 'components/routes/oldLoginRoutes'
import { fetchInviteUserComponentData } from 'components/page/Promo/fetchInviteUserComponentData'
import { withLoader } from 'components/system/loadable/withLoader'
import loadable from '@loadable/component'
import { authorizationCaptchaPath } from 'components/page/Captcha/Captcha.paths'
import { installMobileAppBannerLayerPath } from 'components/banner/InstallAppBanner/InstallMobileAppBannerLayer.paths'
import { InstallMobileAppBannerLayerLoadable } from 'components/banner/InstallAppBanner/InstallMobileAppBannerLayerLoadable'
import { BlockedProfileLoadable } from './page/Boarding/BlockedModalList/BlockedProfileLoadable'
import { supportEdnaRoutes } from './page/SupportEdna/SupportEdnaRoutes'
import { Logout } from './page/Logout/Logout'

const routes: AppRoute[] = [
  ...noticeEmailsRoute,
  ...legacySupportRoutes,
  ...mobileAppSupportRoutes,
  ...emailRoutes,
  ...systemRoutes,
  ...pwaRoutes,
  ...profileInviteFriendsAfterCallbackRoute,
  ...legacyDeletedServiceRoutes,
  ...supportEdnaRoutes,
  {
    path: externalLinkRedirectPath,
    component: loadable(
      () => import('components/page/ExternalLink/ExternalLinkNotice'),
      withLoader
    ),
    exact: false,
  },
  {
    path: profileOkPhotoUploadPath,
    component: ProviderClassMateRedirect,
    exact: true,
  },
  {
    path: installMobileAppBannerLayerPath,
    component: InstallMobileAppBannerLayerLoadable,
    exact: true,
  },
  {
    path: profileOkPhotoUploadVendorErrorPath,
    component: ProviderClassMateRedirect,
    exact: true,
  },
  {
    path: profileFacebookPhotoUploadPath,
    component: ProviderFacebookRedirect,
    exact: true,
  },
  {
    path: settingsPath,
    component: () => (
      <GenericLayoutContextWrapper>
        <SettingsLoadable />
      </GenericLayoutContextWrapper>
    ),
    authorizedOnly: true,
  },
  {
    path: profileAlbumRedirectPath,
    component: ProfileAlbumRedirect,
  },
  {
    path: profileRedirectPath,
    component: ProfileRedirect,
  },
  // Бекенд приходит на этот урл
  {
    path: '/verifyRealUsingSocialNetwork',
    component: VerifyRealUsingSocialNetworkLoadable,
  },
  {
    path: '/real/verify_using_social_network',
    component: VerifyRealUsingSocialNetworkLoadable,
  },
  {
    path: '/my/message.phtml',
    component: LegacyMessengerRedirect,
    exact: false,
  },
  {
    path: '/auth',
    component: () => <Replace to={loginPath} uid="/auth" />,
  },
  {
    path: '/tips',
    component: loadable(
      () =>
        import(
          'components/system/redirect/LegacyTipsRedirect/LegacyTipsRedirect'
        ),
      withLoader
    ),
    exact: false,
  },
  {
    path: '/bbs',
    component: () => (
      <RedirectWithStatus to={announceAddPath} status={301} uid="/bbs" />
    ),
    exact: false,
  },
  {
    path: photoCommentOldPath,
    component: PhotoCommentOldPathRedirect,
    exact: true,
  },
  {
    path: photoviewerOldPath,
    component: PhotoviewerOldPathRedirect,
    exact: true,
  },

  {
    path: '/my/settings_private.phtml',
    component: () => (
      <Replace
        to={mergeAllUrls(settingsPath, settingsPrivacyPath)}
        uid="/my/settings_private.phtml"
      />
    ),
  },
  {
    path: '/register/get_real',
    component: () => (
      <Replace
        to={mergeAllUrls(settingsPath, settingsVerificationPath)}
        uid="/register/get_real"
      />
    ),
  },
  {
    path: registrationPath,
    component: loadable(() => import('components/system/RedirectRegister')),
  },
  {
    path: '/my/hit_list.phtml',
    component: () => (
      <RedirectWithStatus
        status={301}
        to={hitListPath}
        uid="/my/hit_list.phtml"
      />
    ),
  },
  ...backgroundOnlyRoutes,
  {
    path: photoRestrictionsPath,
    component: loadable(() => import('components/page/Legal/PhotoRestriction')),
    shouldCheck: true,
  },
  {
    path: vipPromoPath,
    component: loadable(
      () => import('components/page/Promo/VipPromo/Promo'),
      withLoader
    ),
    shouldCheck: true,
  },
  {
    path: contestPromoPath,
    component: loadable(
      () => import('components/page/Promo/ContestPromo/Contest'),
      withLoader
    ),
    shouldCheck: true,
  },
  {
    path: searchSettingsPath,
    component: MainSearchSettingsLoadable,
  },
  {
    path: datingProfilePath,
    component: DatingProfileLoadable,
    authorizedOnly: true,
  },
  {
    path: '/search/form',
    component: () => <Replace to={searchSettingsPath} uid="/search/form" />,
  },
  {
    path: storeFrontPath,
    component: StandaloneStoreFront,
    authorizedOnly: true,
  },
  /**
   * Для ссылок на витрины из приложений.
   */
  {
    path: shortStoreFrontPath,
    component: StoreFrontLoadable,
    authorizedOnly: true,
  },
  /**
   * Для ссылок на витрины из приложений по OTP.
   */
  {
    path: otpStoreFrontPath,
    component: StoreFrontLoadable,
    authorizedOnly: true,
  },
  {
    path: diamondsPath,
    component: DiamondsModalLoadable,
    shouldCheck: true,
  },
  {
    path: cashOutPath,
    component: CashOutLoadable,
    authorizedOnly: true,
  },
  {
    path: authorizationCaptchaPath,
    component: CaptchaPageLoadable,
  },
  {
    path: mergeAllUrls(appUniWebPath, userProfilePath, photoViewerBasePath),
    component: PhotoViewerLoadable,
    exact: false,
  },
  { path: blockedByIpReasonsPath, component: BlockedByIpListLoadable },
  {
    path: blockedByIpReasonsLegacyPath,
    component: loadable(
      () => import('components/page/Boarding/BlockedModalList/BlockedByIpList'),
      withLoader
    ),
  },
  {
    path: blockedByTrackReasonsPath,
    component: BlockedByTrackLoadable,
    shouldCheck: true,
  },
  {
    path: blockedByTrackReasonsLegacyPath,
    component: loadable(
      () =>
        import('components/page/Boarding/BlockedModalList/BlockedByTrackView'),
      withLoader
    ),
    shouldCheck: true,
  },
  {
    path: blockedByPersonalPath,
    component: loadable(
      () => import('components/page/Boarding/BlockedModalList/BlockedPersonal'),
      withLoader
    ),
    authorizedOnly: true,
  },
  {
    path: blockedProfilePath,
    component: BlockedProfileLoadable,
    authorizedOnly: true,
  },
  {
    path: logoutPath,
    component: Logout,
    authorizedOnly: true,
  },
  {
    path: featuredPhotosPath,
    component: FeaturedPhotosStartLoadable,
    authorizedOnly: true,
  },
  {
    path: uniNoticeFullPath,
    component: GenericUniNotice,
    shouldCheck: true,
    exact: false,
  },
  /**
   * Для заблокированного пользователя, иначе будут редиректы.
   * https://redmine.mamba.ru/issues/113574
   */
  {
    path: supportFormPath,
    component: SupportFormLoadable,
    /** нужно для появлении каптчи */
    shouldCheck: true,
    exact: false,
  },
  ...deleteProfileRoutes,
  {
    path: downloadAppPath,
    component: GetLinkForDownloadAppLoadable,
  },
  {
    path: mergeAllUrls(appUniWebPath, restorePasswordPath),
    component: RestorePasswordLoadable,
  },
  {
    path: guaranteedImpressionsResultPath,
    component: GuaranteedImpressionsResultLoadable,
    authorizedOnly: true,
  },
  {
    path: '/invite_reg.phtml',
    component: InviteFriendLoadable,
    shouldCheck: true,
    exact: false,
  },
  {
    path: searchFriendPath,
    component: InviteFriendLoadable,
    fetchInitialComponentData: fetchInviteUserComponentData,
    shouldCheck: true,
    exact: false,
  },
  {
    path: stepRegistrationPath,
    component: () => <Replace to={indexPath} uid="stepRegistrationPath" />,
    unauthorizedOnly: true,
  },
  // {
  //   path: stepRegistrationStepPath,
  //   component: IndexStepEntry,
  //   unauthorizedOnly: true,
  // },
  {
    path: notFoundPath,
    component: NotFoundPageLoadable,
    shouldCheck: true,
  },
  {
    path: internalServerErrorPath,
    component: loadable(
      () =>
        import(
          'components/page/InternalServerErrorPage/InternalServerErrorPageIndex'
        ),
      withLoader
    ),
    shouldCheck: true,
  },
  ...oldLoginRoutes,
  {
    path: indexPath,
    component: IndexSwitcher,
    shouldCheck: true,
    exact: false,
  },
]

export default routes
